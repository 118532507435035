<template>
  <div v-if="showPage">
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">套餐</span>
      > 编辑
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <el-form ref="form" label-width="200px" style="margin-top: 30px">
          <el-form-item size="small" label="套餐名称:" required>
            <el-input v-model="item.packageName" placeholder="请输入套餐名称" maxlength="64" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="可购买日期:" required>
            <el-date-picker
              style="width: 197px"
              v-model="item.purchaseStartDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="开始日期">
            </el-date-picker>
            <el-date-picker
              style="width: 197px;margin-left: 11px"
              v-model="item.purchaseEndDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="结束日期">
            </el-date-picker>
            <span style="margin-left: 15px;color: #8c939d">套餐可购买日期时间段</span>
          </el-form-item>
          <el-form-item size="small" label="套餐类型:" required>
            <el-radio-group v-model="item.packageType">
              <el-radio :label="item.dictValue" v-for="item in packageTypeList">{{item.name}}</el-radio>
            </el-radio-group>
            <span style="margin-left: 15px;color: #8c939d">仅在志邦平台购买和消耗</span>
          </el-form-item>
          <el-form-item size="small" label="有效期:" required>
            <el-radio-group v-model="item.tovType">
              <el-radio :label="0">有效期
                <el-input v-model="item.tovMonth" size="small"
                          style="width: 60px;margin-left: 10px;margin-right: 10px"></el-input>
                个月
                <span style="margin-left: 15px;color: #8c939d">从购买日期开始延迟多少个月到期</span>
              </el-radio>
              <br/>
              <el-radio style="margin-top: 20px" :label="1">指定到期日期
                <el-date-picker
                  style="width: 140px;margin-left: 11px"
                  v-model="item.tovDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="到期日期">
                </el-date-picker>
                <span style="margin-left: 15px;color: #8c939d">为套餐固定到期日期；不填为永久有效</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item size="small" label="添加服务项目:" required>
            <div class="item-list" v-if="items.length>0">
              <tr class="item-tr" v-for="item in items">
                <td style="width: 20%">{{'('+item.itemNo+') ' +item.itemName}}</td>
                <td>
                  <el-input size="small" v-model="item.itemNum" v-on:change="setItemCost">
                    <template slot="append">次</template>
                  </el-input>
                </td>
                <td>
                  <el-input size="small" v-model="item.itemAmount" v-on:change="setItemCost">
                    <template slot="append">元</template>
                  </el-input>
                </td>
                <td>
                  <span style="margin-right: 8px">记业绩</span>
                  <el-input style="width:70%;" size="small" v-model="item.performance">
                    <template slot="append">次/元</template>
                  </el-input>
                </td>
              </tr>
            </div>
            <el-button size="small" type="primary" @click="siView">添加服务项目</el-button>
          </el-form-item>
          <el-form-item size="small" label="添加商品:" required>
            <div class="item-list" v-if="skus.length>0">
              <tr class="item-tr" v-for="item in skus">
                <td style="width: 35%">{{'('+item.skuNo+') ' +item.skuName}}</td>
                <td>
                  <el-input size="small" v-model="item.skuNum" v-on:change="setSkuCost">
                    <template slot="append">次</template>
                  </el-input>
                </td>
                <td>
                  <el-input size="small" v-model="item.skuAmount" v-on:change="setSkuCost">
                    <template slot="append">元</template>
                  </el-input>
                </td>
              </tr>
            </div>
            <el-button size="small" type="primary" @click="skuView">添加商品</el-button>
          </el-form-item>
          <el-form-item size="small" label="套餐成本:" required>
            <el-input disabled v-model="packageCost" placeholder="请输入套餐成本" maxlength="12" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="套餐优惠价格:" required>
            <el-input v-model="item.discount" placeholder="请输入套餐成本" maxlength="12" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>

          <el-form-item size="small" label="手动赠送项目/商品:" required>
            <el-radio-group v-model="item.isGive">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>

            <div class="item-list" v-if="gives.length>0&&item.isGive==0">
              <tr class="item-tr" v-for="item in gives">
                <td style="width: 20%">{{'('+item.no+') ' +item.name}}</td>
                <td>
                  <el-input size="small" v-model="item.giveNum">
                    <template slot="append">次</template>
                  </el-input>
                </td>
                <td>
                  <el-input size="small" v-model="item.giveAmount">
                    <template slot="append">元</template>
                  </el-input>
                </td>
                <td>
                  <div v-if="item.giveType==0">
                    <span style="margin-right: 8px">记业绩</span>
                    <el-input style="width:70%;" size="small" v-model="item.performance">
                      <template slot="append">次/元</template>
                    </el-input>
                  </div>
                </td>
              </tr>
            </div>
            <p>
              <el-button v-if="item.isGive==0" size="small" type="primary" @click="giveView">添加赠送</el-button>
            </p>
          </el-form-item>

<!--          <el-form-item size="small" label="可销售门店:" required>-->
<!--            <el-radio-group v-model="item.salesStoresStatus">-->
<!--              <el-radio :label="0">可用</el-radio>-->
<!--              <el-radio :label="1">禁用</el-radio>-->
<!--            </el-radio-group>-->
<!--            <span style="margin-left: 15px;color: #8c939d">-->
<!--          说明: "可用" 表示勾选的门店可销售-->
<!--        </span>-->
<!--            <div style="width: 600px">-->
<!--              <el-checkbox v-model="checkAll" @change="checkAllEvent">全选</el-checkbox>-->
<!--              <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>-->
<!--              <template v-for="(sd,key) in cities">-->
<!--                <div style="margin: 15px 0;"></div>-->
<!--                <el-checkbox v-model="sd.checkAll" @change="handleCheckAllChange(key)">{{sd.name}}-->
<!--                </el-checkbox>-->
<!--                <div style="margin: 15px 0;"></div>-->
<!--                <el-checkbox-group v-model="sd.checkedCities" @change="handleCheckedCitiesChange(key)">-->
<!--                  <el-checkbox v-for="item in sd.list" :label="item.id" :key="item.id">{{item.departmentName}}-->
<!--                  </el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--                <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>-->
<!--              </template>-->
<!--            </div>-->
<!--          </el-form-item>-->
        </el-form>
        <div class="card-footer" style="width: 515px;margin-bottom: 30px">
          <el-button size="small" @click="closeEvent">取 消</el-button>
          <el-button size="small" type="primary" @click="savePackage">确 定</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="选择服务项目" :visible.sync="viewVisible" width="45%">
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 5px" v-if="serviceItemList.length>0"
           v-for="item in serviceItemList">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
              {{item.categoryName}}:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="itemIds">
                <el-checkbox style="margin-top: 3px" v-for="it in item.serviceItemList" :label="it.id"
                             :key="it.id">
                  {{it.itemName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="viewVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setChecked">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="skuVisible" width="45%">
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 8px" v-if="skuList" v-for="item in skuList">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
              {{item.categoryName}}:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="skuIds">
                <el-checkbox style="margin-top: 3px" v-for="it in item.skuList" :label="it.id" :key="it.id">
                  {{it.skuName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="skuVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setSku">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="选择赠送" :visible.sync="giveVisible" width="45%">
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 8px" v-if="serviceItemList"
           v-for="item in serviceItemList">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
               {{item.categoryName}}:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="giveItemIds">
                <el-checkbox style="margin-top: 3px" v-for="it in item.serviceItemList" :label="it.id" :key="it.id">
                  {{it.itemName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="border: 1px solid #ddd;padding: 10px;margin-bottom: 8px" v-if="skuList" v-for="item in skuList">
        <el-row>
          <el-col :span="5">
            <span style="margin-top: 10px;font-weight:bold;font-size: 20px">
              {{item.categoryName}}:
            </span>
          </el-col>
          <el-col :span="19">
            <div>
              <el-checkbox-group v-model="giveSkuIds">
                <el-checkbox style="margin-top: 3px" v-for="it in item.skuList" :label="it.id" :key="it.id">
                  {{it.skuName}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="giveVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="setGive">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>
<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      const cityOptions = ['北京店', '天津店'];
      return {
        showPage: false,
        Base_url: '',
        checked: '',

        pId: '',

        item: {},

        viewVisible: false,
        skuVisible: false,
        giveVisible: false,

        itemIds: [],
        items: [],
        serviceItemList: [],

        skuIds: [],
        skuList: [],
        skus: [],

        giveSkuIds: [],
        giveItemIds: [],
        gives: [],

        categoryList: [],
        packageTypeList: [],

        checkAll: false,
        allCheckCities: [], // 全部选中得数据
        cities: {},
        checkTotal: 0,

        packageCost: '',
        itemCost: 0,
        skuCost: 0,
      }
    },

    async created() {
      this.Base_url = Base_url
      this.pId = this.$route.query.id
      await this.getBsServiceItemList()
      await this.getSkuList()
      await this.getPackage()
      // await this.searchEvent()
      this.getPackageTypeList()

    },
    methods: {
      async getBsServiceItemList() {
        let res = await this.$get("/admin/getBsServiceItemList")
        if (res.code == 200) {
          this.serviceItemList = res.serviceItemList
        }
      },

      async getSkuList() {
        let res = await this.$get("/admin/getSkuList")
        if (res.code == 200) {
          this.skuList = res.skuList
        }
      },

      async getPackageTypeList() {
        let res = await this.$get('/admin/getDictDetailList/PACKAGE_TYPE/' + Math.random());
        this.packageTypeList = res;
      },

      async getPackage() {
        let res = await this.$get("/admin/getPackage", {id: this.pId})
        if (res.code == 200) {
          this.item = res.item
          this.allCheckCities = [...res.item.departmentIds]
          this.packageCost = res.item.packageCost
          let skuArr = []
          this.skuList.forEach(item => {
            if (item.skuList.length > 0) {
              item.skuList.forEach(it => {
                res.item.packageSkus.forEach(i => {
                  if (i.skuId == it.id) {
                    let data = {
                      skuId: it.id,
                      skuName: it.skuName,
                      skuNo: it.skuNo,
                      skuAmount: i.skuAmount,
                      skuNum: i.skuNum,
                    }
                    this.skus.push(data)
                    this.skuIds.push(i.skuId)
                  }
                })
                res.item.packageGives.forEach(i => {
                  if (i.giveId == it.id) {
                    let give = {
                      giveId: it.id,
                      name: it.skuName,
                      no: it.skuNo,
                      giveAmount: i.giveAmount,
                      giveNum: i.giveNum,
                      giveType: i.giveType,
                      performance: i.performance,
                    }
                    skuArr.push(give)
                    this.giveSkuIds.push(it.id)
                  }
                })
              })
            }
          })
          let itemArr = []
          this.serviceItemList.forEach(item => {
            if (item.serviceItemList.length > 0) {
              item.serviceItemList.forEach(it => {
                res.item.packageItems.forEach(i => {
                  if (i.itemId == it.id) {
                    let data = {
                      itemId: it.id,
                      itemName: it.itemName,
                      itemNo: it.itemNo,
                      itemAmount: i.itemAmount,
                      itemNum: i.itemNum,
                      performance: i.performance,
                    }
                    this.items.push(data)
                    this.itemIds.push(it.id)
                  }
                })
                res.item.packageGives.forEach(i => {
                  if (i.giveId == it.id) {
                    let give = {
                      giveId: it.id,
                      name: it.itemName,
                      no: it.itemNo,
                      giveAmount: i.giveAmount,
                      giveNum: i.giveNum,
                      giveType: i.giveType,
                      performance: i.performance,
                    }
                    itemArr.push(give)
                    this.giveItemIds.push(it.id)
                  }
                })
              })

            }
          })
          this.gives = [...itemArr, ...skuArr]
          this.showPage = true
        }
      },

      setItemCost() {
        let ac = 0;
        this.items.forEach(item => {
          ac += parseInt(item.itemAmount) * parseInt(item.itemNum)
        })
        this.itemCost = parseInt(ac)
        this.packageCost = parseInt(ac) + parseInt(this.skuCost)
      },
      setSkuCost() {
        let ac = 0;
        this.skus.forEach(item => {
          ac += parseInt(item.skuAmount) * parseInt(item.skuNum)
        })
        this.skuCost = parseInt(ac)
        this.packageCost = parseInt(ac) + parseInt(this.itemCost)
      },

      setChecked() {
        this.items = []
        this.serviceItemList.forEach(item => {
          if (item.serviceItemList.length > 0) {
            item.serviceItemList.forEach(it => {
              this.itemIds.forEach(i => {
                if (i == it.id) {
                  let data = {
                    itemId: it.id,
                    itemName: it.itemName,
                    itemNo: it.itemNo,
                    itemAmount: '',
                    itemNum: '',
                    performance: '',
                  }
                  this.items.push(data)
                }
              })
            })
          }
        })
        this.viewVisible = false
      },
      setSku() {
        this.skus = []
        this.skuList.forEach(item => {
          if (item.skuList.length > 0) {
            item.skuList.forEach(it => {
              this.skuIds.forEach(i => {
                if (i == it.id) {
                  let data = {
                    skuId: it.id,
                    skuName: it.skuName,
                    skuNo: it.skuNo,
                    skuAmount: '',
                    skuNum: '',
                  }
                  this.skus.push(data)
                }
              })
            })
          }
        })
        this.skuVisible = false
      },
      setGive() {
        let itemArr = []
        this.serviceItemList.forEach(item => {
          if (item.serviceItemList.length > 0) {
            item.serviceItemList.forEach(it => {
              this.giveItemIds.forEach(i => {
                if (i == it.id) {
                  let data = {
                    giveId: it.id,
                    name: it.itemName,
                    no: it.itemNo,
                    giveAmount: '',
                    giveNum: '',
                    giveType: '0',
                    performance: '',
                  }
                  itemArr.push(data)
                }
              })
            })
          }
        })
        let skuArr = []
        this.skuList.forEach(item => {
          if (item.skuList.length > 0) {
            item.skuList.forEach(it => {
              this.giveSkuIds.forEach(i => {
                if (i == it.id) {
                  let data = {
                    giveId: it.id,
                    name: it.skuName,
                    no: it.skuNo,
                    giveAmount: '',
                    giveNum: '',
                    giveType: '1',
                    performance: '',
                  }
                  skuArr.push(data)
                }
              })
            })
          }
        })
        this.gives = [...itemArr, ...skuArr]
        this.giveVisible = false
      },


      siView() {
        this.viewVisible = true
      },
      skuView() {
        this.skuVisible = true
      },
      giveView() {
        this.giveVisible = true
      },
      closeEvent() {
        this.$router.push('/baseData/serviceItem?index=1')
      },
      async savePackage() {
        if (!this.item.packageName) {
          this.$message.error("'请填写套餐名称'")
          return
        }
        if (!this.item.purchaseStartDate && !this.item.purchaseEndDate) {
          this.$message.error("'请选择可购买日期'")
          return
        }
        if (!this.item.packageType && !this.item.packageType == 0) {
          if (!this.tovMonth) {
            this.$message.error("'请填写有效期多少个月'")
            return
          }

        } else if (!this.item.packageType && !this.packageType == 1) {
          if (!this.item.tovDate) {
            this.$message.error("'请选择指定到期日期'")
            return
          }
        }
        if (!this.item.discount) {
          this.$message.error("'请填写优惠价格'")
          return
        }

        let data = {
          id: this.item.id,
          packageName: this.item.packageName,
          startDate: this.item.purchaseStartDate,
          endDate: this.item.purchaseEndDate,
          packageType: this.item.packageType,
          tovType: this.item.tovType,
          tovMonth: this.item.tovMonth,
          tDate: this.item.tovDate,
          packageCost: this.packageCost,
          discount: this.item.discount,
          itemId: this.item.itemId,
          isGive: this.item.isGive,
          serviceStoresStatus: this.item.salesStoresStatus,
          salesStoresStatus: this.item.serviceStoresStatus,
          itemStr: JSON.stringify(this.items),
          skuStr: JSON.stringify(this.skus),
          giveStr: JSON.stringify(this.gives),
          departmentIdStr: JSON.stringify(this.allCheckCities)
        };
        let res = await this.$post("/admin/save_bsPackage", data);
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/baseData/serviceItem?index=1')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },
      // async searchEvent() {
      //   let checkTotal = 0;
      //   let cities = {};
      //   let data = await this.$get("/admin/getDepartmens")
      //   let res = data
      //   for (let i = 0; i < res.length; i++) {
      //     let item = res[i];
      //     let sysDepartmentList = item['sysDepartmentList'];
      //     let ids = sysDepartmentList.map(sd => (sd.id));
      //     let checkedCities = this.allCheckCities.filter(item => {
      //       return ids.indexOf(item) > -1;
      //     });
      //     checkTotal += item['sysDepartmentList'].length;
      //     let checkAll = checkedCities.length === ids.length;
      //     cities[item.id] = {
      //       ids,
      //       checkAll,
      //       checkedCities,
      //       name: item.departmentName,
      //       list: item['sysDepartmentList']
      //     }
      //   }
      //   this.checkTotal = checkTotal;
      //   this.cities = cities;
      // },
      // 监听 group 选择
      handleCheckedCitiesChange(key) {
        let item = this.cities[key];
        item.checkAll = item.ids.length === item.checkedCities.length;
        this.getCheckedLen()
      },
      // 监听全选
      handleCheckAllChange(key) {
        let item = this.cities[key];
        let checkAll = item.checkAll;
        item.checkedCities = checkAll ? item.ids : [];
        this.getCheckedLen()
      },
      checkAllEvent() {
        let cities = this.cities;
        if (this.checkAll) {
          for (let key in  cities) {
            let item = cities[key];
            item.checkedCities = item.ids;
            item.checkAll = true;
          }
        } else {
          for (let key in  cities) {
            let item = cities[key];
            item.checkAll = false;
            item.checkedCities = [];
          }
        }
        this.cities = cities;
        this.getAllCheckCityes();
      },
      getAllCheckCityes() {
        let allCheckCities = [];
        for (let key in  this.cities) {
          let item = this.cities[key];
          allCheckCities = [...allCheckCities, ...item.checkedCities]
        }
        this.allCheckCities = allCheckCities;
      },
      getCheckedLen() {
        let len = 0;
        for (let key in  this.cities) {
          let item = this.cities[key];
          len += item.checkedCities.length
        }
        if (this.checkTotal === len) {
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
        this.getAllCheckCityes();
      },

    }
  }
</script>

<style lang="scss">
  .item-list {
    background-color: #c0c4cc;
    width: 70%;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    padding: 8px;
    margin-bottom: 5px;
  }

  .item-tr {
    line-height: 36px;
    height: 36px;
    td {
      line-height: 32px;
      padding: 0px 5px;
    }
  }

</style>
